import styled, { css } from 'styled-components';
import Config from '@config';
import { rem, rgba } from 'polished';
import variables from '@styles/variables';
import Link from '@atoms/Link/Link';
import Heading from '@atoms/Heading/Heading';
import Spinner from '@components/molecules/Spinner/Spinner';
import Button from '@atoms/Button/Button';

export const StyledCartPreview = styled.div<{ isOpen: boolean; backdropVisible?: boolean }>`
  margin-left: auto;
  z-index: ${variables.zIndex.cart};
  max-width: 0;
  height: 0;
  width: 100%;
  top: ${rem(variables.size.toolbarHeight)};
  background-color: ${variables.colors.white};
  transition: max-width ease ${variables.animation.layout};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  ${(props) =>
    props.backdropVisible &&
    css`
      z-index: ${variables.zIndex.toolbar + 2};
    `}

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    position: sticky;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.isOpen &&
    css`
      display: none;
      max-width: ${rem(variables.size.cartPreviewWidth)};
      border-left: ${rem(1)} solid ${variables.colors.mediumGray};

      ${Config.MEDIA.FROM_DESKTOP} {
        display: flex;
      }
    `}
`;

export const StyledCartPreviewHeader = styled.header`
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
`;

export const StyledCartPreviewHeaderInnerWrapper = styled.div`
  position: relative;
  display: flex;
  padding: ${rem(14)} ${rem(0)} ${rem(18)};
  margin: ${rem(4)} ${rem(16)} ${rem(0)};
`;

export const StyledAddToListButton = styled(Button)`
  align-self: center;
  height: ${rem(20)};
  margin-right: ${rem(15)};
`;

export const StyledCartPreviewHeaderHeading = styled(Heading)`
  flex-grow: 1;
`;

export const StyledCartPreviewBodyWrapper = styled.section`
  min-width: ${rem(variables.size.cartPreviewWidth)};
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

/**
 * @param shadowFactor 0.0-1.0
 */
export const StyledCartPreviewFooter = styled.section<{ shadowFactor: number }>`
  ${(props) =>
    css`
      box-shadow: ${rem(0)} ${rem(-5)} ${rem(50)} ${rem(-15)}
        ${rgba(variables.colors.lighterBlack, 0.2 * props.shadowFactor)};
    `}
  min-width: ${rem(variables.size.cartPreviewWidth)};
  border-top: ${rem(1)} solid ${variables.colors.mediumGray};
  padding: ${rem(11)} ${rem(16)} ${rem(16)};
  z-index: ${variables.zIndex[1]};
`;

export const StyledCartPreviewFooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${rem(8)};
`;

export const StyledCartPreviewGoToCartLink = styled(Link)`
  width: 100%;
`;

export const StyledCartPreviewGoToCartButton = styled(Button)`
  width: 100%;
`;

export const StyledCartPreviewContainer = styled.div`
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${rem(28)};
`;

export const StyledSpinner = styled(Spinner)`
  margin: ${rem(12)} 0;
`;
